import http from '@/utils/http';

// 获取
export const getUniqueTournamentTrans = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/trans/get',
        method: 'get',
        params: params
    })
}

// 更新
export const updateUniqueTournamentTrans = (params) => {
    return http.request({
        url: "/v1/ballsports/base/unique_tournament/trans/update",
        method: 'post',
        data: params,
    })
}

export const getUniqueTournamentShortTrans = (params) => {
    return http.request({
        url: '/v1/ballsports/base/unique_tournament/trans_short/get',
        method: 'get',
        params: params
    })
}

// 更新
export const updateUniqueTournamentShortTrans = (params) => {
    return http.request({
        url: "/v1/ballsports/base/unique_tournament/trans_short/update",
        method: 'post',
        data: params,
    })
}
