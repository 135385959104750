<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Drawer
            v-model="drawer_trans"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <uniqueTournamentTransEdit :sport_id="sport_id" :unique_tournament_id="trans_id" @closePage="closeTransPage"></uniqueTournamentTransEdit>
        </Drawer> 

        <Drawer
            v-model="drawer_trans_short"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <uniqueTournamentShortTransEdit :sport_id="sport_id" :unique_tournament_id="trans_id" @closePage="closeTransPage"></uniqueTournamentShortTransEdit>
        </Drawer> 

        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="父级赛事: ">
                <Row>
                    <Col span="20" >
                        <uniqueTournamentSearch v-model="data.parent" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'parent_id'] === 1? 'locked-input': '' "></uniqueTournamentSearch>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'parent_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="parent_id"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="name"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="简称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.short_name" :class="locks[String(type)+'-1-'+'short_name'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'short_name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="short_name"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="类别: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.category_id" :class="locks[String(type) + '-1-' + 'category_id'] === 1? 'locked-select': '' ">
                            <Option v-for="item in category_dic" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-' + 'category_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="category_id"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="国家: ">
                <Row>
                    <Col span="20" >
                        <countrySearch v-model="data.country" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'country_id'] === 1? 'locked-input': '' "></countrySearch>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'country_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="country_id"></Lock>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="logo: ">
                <Row>
                    <imgEditor v-model="data.logo" :prefix="$conf.ballsports.img_prefix" :dir="sport_id===6? 'baseball/competition': (sport_id===10 ? 'volleyball/competition': 'v2/unique-tournament')" 
                        :previewButton='true' :clearButton='true' cdn='ballsports'>
                    </imgEditor>
                    <!-- <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'country_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="country_id"></Lock>
                    </Col> -->
                </Row>
            </FormItem>

            <FormItem label="中文简体: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_zh" :class="locks[String(type)+'-1-'+'name_zh'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'name_zh']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="name_zh"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="中文繁体: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_zht" :class="locks[String(type)+'-1-'+'name_zht'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'name_zht']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="name_zht"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="英文: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_en" :class="locks[String(type)+'-1-'+'name_en'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'name_en']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="name_en"></Lock>
                    </Col>
                </Row>
            </FormItem>
            
            <FormItem label="是否删除: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                            <Option :value="1" key="1">是</Option>
                            <Option :value="0" key="0">否</Option>
                        </Select>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="unique_tournament_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="全称翻译: ">
                <Row>
                    <Col span="20" >
                        <Button type="default" long @click="editTrans">翻译</Button>
                    </Col>
                </Row>
            </FormItem>
            
            <FormItem label="简称翻译: ">
                <Row>
                    <Col span="20" >
                        <Button type="default" long @click="editShortTrans">翻译</Button>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import uniqueTournamentSearch from '../unique_tournament/search.vue';
import countrySearch from "../country/search.vue";
import Lock from '../lock.vue';
import { getUniqueTournament, getUniqueTournamentCategory, updateUniqueTournament } 
    from '@/api/ballsports/base/unique_tournament';
import imgEditor from '@/components/img-editor/img-editor.vue';
import uniqueTournamentTransEdit from './trans/edit.vue';
import uniqueTournamentShortTransEdit from './trans_short/edit.vue';

export default {
    name: "unique-tournament-edit",
    props: {
        sport_id: Number,   // 运动id
        unique_tournament_id: Number,   // 联赛id
    },
    components: {
        Lock,
        uniqueTournamentSearch,
        countrySearch,
        imgEditor,
        uniqueTournamentTransEdit,
        uniqueTournamentShortTransEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 2,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                parent:{},
                category: {},
                country: {}
            },
            locks: {},
            category_dic: {},
            drawer_trans: false,
            trans_id: 0,
            drawer_trans_short: false,
        }
    },
    methods: {
        // 获取联赛分类
        getCategorys(){
            var params = {
                sport_id: this.sport_id,
            }
            getUniqueTournamentCategory(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.category_dic = response.data.data;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        getData () {
            let self = this;
            if (self.unique_tournament_id && self.unique_tournament_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.unique_tournament_id,
                }
                // console.log(params)
                getUniqueTournament( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks ;
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
            }
            
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },
        save() {
            let self = this;
            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                parent_id: self.data.parent.id,
                category_id: self.data.category_id,
                country_id: self.data.country.id,
                name: self.data.name,
                short_name: self.data.short_name,
                logo: self.data.logo,
                deleted: self.data.deleted,
                // 更新翻译表的数据
                name_zh : self.data.name_zh,
                name_zht: self.data.name_zht,
                name_en : self.data.name_en,
            }
            updateUniqueTournament(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        // end
        editTrans(){
            let self = this;
            self.drawer_trans_short = false;
            self.drawer_trans = true;
            self.trans_id = self.unique_tournament_id;
        },
        editShortTrans(){
            let self = this;
            self.drawer_trans = false;
            self.drawer_trans_short = true;
            self.trans_id = self.unique_tournament_id;
        },
        // end 
        closeTransPage(){
            let self = this;
            self.drawer_trans = false;
            self.drawer_trans_short = false;
            self.trans_id = 0;
        },
        // end

    },
    mounted () {
        let self = this;
        self.getCategorys();
        // self.getData();
    },
    watch: {
        'unique_tournament_id': {
            handler(value){
                // console.log("Detail Get Data", value);
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>